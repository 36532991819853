<template>
  <div>
    <div>
       welcome
    </div>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  data(){
    return{

    }
  }
}
</script>

<style scoped>

</style>
